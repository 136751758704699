import React from "react";
import { AppRouting } from "./App.routing";
import Switch from "./components/Switch";
function App() {
  return (
    <>
      <Switch />
      <AppRouting></AppRouting>
    </>
  );
}

export default App;
