import React, { Component } from "react";
import $ from "jquery";

export default class TopButton extends Component {
  componentDidMount() {
    const introHeight = document.querySelector(".intro").offsetHeight;
    const topButton = document.getElementById("top-button");
    const $topButton = $("#top-button");

    window.addEventListener(
      "scroll",
      function () {
        if (window.scrollY > introHeight) {
          $topButton.fadeIn();
        } else {
          $topButton.fadeOut();
        }
      },
      false
    );

    topButton.addEventListener("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 500);
    });
  }
  render() {
    return (
      <>
        <button id="top-button">
          <img src="/img/emojis/pointing-up.png" alt="Goto Top" />
        </button>
      </>
    );
  }
}
