import React, { Component } from "react";
import "./Projects.css";
export default class Projects extends Component {
  constructor() {
    super();
    this.state = {
      otherProjects: [
        {
          name: "BCA Stuffs",
          url: "https://apkpure.com/bca-stuffs/com.sdinnovations.bcastuffs",
          code: "",
          type: "Application Development",
          image:
            "https://lh3.googleusercontent.com/kIHeziemC734o_Vy7aN4AsTYkiycoXQBrqhHiB4QEl8eoFgBmgQx52yIUFNFbhIr4EHS",
          description:
            "A android application built for notes, syllabus, news, notice and results for all semester students" +
            " who are studying Bachelor of Computer Application degree under Pokhara University.",
          used: ["Java"],
          platform: "Mobile",
          year: 2019,
          status: "live",
        },
        {
          name: "Zip / Postal Codes Nepal",
          url:
            "https://apkpure.com/zip-postal-codes-nepal/com.sdinnovations.zippostalcodesnepal",
          code: "https://github.com/TheDebid/ZIp-Code-Nepal",
          type: "Application Development",
          image:
            "https://image.winudf.com/v2/image1/Y29tLnNkaW5ub3ZhdGlvbnMuemlwcG9zdGFsY29kZXNuZXBhbF9pY29uXzE1NTg4NDI4MjBfMDQ1/icon.png?w=170&fakeurl=1",
          description:
            "A android application which is specially designed and developed to make easy to search and view postal or zip codes of Nepal.",
          used: ["Java"],
          platform: "Mobile",
          year: 2019,
          status: "live",
        },
        {
          name: "Magar Bhasa",
          url: "https://apkpure.com/magar-bhasa/com.sdinnovations.magarbhasa",
          type: "Application Development",
          image:
            "https://image.winudf.com/v2/image1/Y29tLnNkaW5ub3ZhdGlvbnMubWFnYXJiaGFzYV9pY29uXzE1ODU2NzEzMTNfMDQ4/icon.png?w=170&fakeurl=1",
          description:
            "Magar Bhasa - is the must-have app for all Magars of Nepal. That teach you everything from the Magar alphabet to Magar grammars and train your listening, speaking, reading, and writing skills of Magar language",
          used: ["Dart", "Flutter"],
          platform: "Mobile",
          year: 2020,
          status: "live",
        }
      ],
    };
  }
  render() {
    return (
      <>
        <OtherProjects project={this.state.otherProjects} />
      </>
    );
  }
}

const OtherProjects = (props) => {
  return (
    <>
      <section className="section other-projects">
        <div className="section__title">Other Projects</div>
        <div className="section__content">
          {props.project.map((item, index) => {
            return (
              <div className="ProjectContainer" key={index}>
                <div className="ProjectWrapper ">
                  <div className="project_inner">
                    <div className="project_textContainer">
                      <span>
                        <div className="project__name">
                          <a
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="arrow-link"
                          >
                            {item.name}
                          </a>
                        </div>
                      </span>
                      <div className="project__used">
                        <span className="project__status">
                          <span
                            class={
                              item.status === "live"
                                ? "dot status-green"
                                : "dot status-default"
                            }
                          ></span>
                          Status : {item.status}
                        </span>
                      </div>
                      <span>
                        <p>{item.type}</p>
                      </span>
                      <div className="project_desc">
                        <p>{item.description}</p>
                      </div>
                      <div className="project__used">
                        <span className="project__technology__used">
                          Technology Used:
                        </span>
                        {item.used.map((lang, i) => (
                          <span className="project__used__item" key={i}>
                            {lang}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="project_imageContainer">
                      <img
                        src={
                          item.image
                            ? item.image
                            : "https://img.favpng.com/4/1/17/react-javascript-vue-js-logo-png-favpng-T97hHj5T2UsnURsbZ0PB5Mi3c.jpg"
                        }
                        alt="Facebook logo"
                        className="projectImage"
                      />
                      <div className="ProjectTag">
                        <div className="project_url ">
                          {item.code ? (
                            <div className="project_url_icon">
                              <a
                                href={item.code}
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Check repository"
                                className="project_link"
                              >
                                <span
                                  aria-hidden="true"
                                  className="fa fa-github"
                                ></span>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="project_url_icon">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              title="See project"
                              className="project_link"
                            >
                              <span
                                aria-hidden="true"
                                className="fa fa-globe"
                              ></span>
                            </a>
                          </div>
                        </div>
                        <div className="project_platform">{item.platform}</div>

                        <div className="project_year">{item.year}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
