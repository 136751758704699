import React, { Component } from "react";

export default class Intro extends Component {
  componentDidMount() {
    const hand = document.querySelector(".emoji.wave-hand");

    function waveOnLoad() {
      hand.classList.add("wave");
      setTimeout(function () {
        hand.classList.remove("wave");
      }, 2000);
    }

    setTimeout(function () {
      waveOnLoad();
    }, 1000);

    hand.addEventListener("mouseover", function () {
      hand.classList.add("wave");
    });

    hand.addEventListener("mouseout", function () {
      hand.classList.remove("wave");
    });
  }

  render() {
    return (
      <>
        <header className="intro">
          <h1 className="intro__hello">
            Hello!
            <span className="emoji wave-hand animated"></span>
          </h1>

          <h2 className="intro__tagline">
            I'm
            <span className="name"> Debid Magar</span>, a design-minded{" "}
            <strong>Software Engineer</strong> focused on building awesome
            products <span className="emoji technologist"></span>
          </h2>

          <h3 className="intro__contact">
            <span>Get in touch </span>
            <span className="emoji pointer"></span>
            <span>
              <a
                href="mailto:linkwithdm@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="highlight-link"
              >
                linkwithdm@gmail.com
              </a>
            </span>
          </h3>
        </header>
      </>
    );
  }
}
