import React, { Component } from "react";

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      social: [
        {
          url: "https://github.com/TheDebid",
          title: "github",
        },
        {
          url: "https://twitter.com/TheDebid",
          title: "twitter",
        },
        {
          url: "https://www.linkedin.com/in/TheDebid",
          title: "linkedin",
        },
        {
          url: "https://www.instagram.com/TheDebid",
          title: "instagram",
        },
      ],
    };
  }

  render() {
    return (
      <>
        <footer className="footer">
          <div className="footer__copyright">
            <div className="top">
              <span>Designed &amp; Developed by</span>
            </div>
            <div className="bottom">
              <span>Debid Magar</span>
              <img className="emoji" src="/img/emojis/rockon.png" alt="emoji" />
              <span>2020</span>
            </div>
          </div>
          <div className="footer__links">
            {this.state.social.map((item, index) => (
              <a
                href={item.url}
                rel="noopener noreferrer"
                target="_blank"
                key={index}
              >
                <span className="text">{item.title}</span>
                <img src={`/img/social/${item.title}.svg`} alt={item.title} />
              </a>
            ))}
          </div>
        </footer>
      </>
    );
  }
}
