import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class NotFound extends Component {
  render() {
    return (
      <>
        <div className="not-found">
          <div className="name">Debid Magar</div>
          <img src="/img/emojis/thinking-face.png" alt="404 Not Found" />
          <h1>Hmmm...</h1>
          <h2>
            Looks like there's nothing here.
            <br />
            Maybe you should go back <Link to="/">Home</Link>.
          </h2>
        </div>
      </>
    );
  }
}
