import React, { Component } from "react";

export default class Background extends Component {
  render() {
    return (
      <>
        <section className="section background">
          <div className="section__title">Background</div>
          <div className="section__content">
            <p>
              I'm a <strong>Software Engineer</strong> having very good hands on
              mobile and web development. I recently graduated from
              <a
                className="underline-link"
                href="http://pu.edu.np/"
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp; Pokhara University
              </a>
            </p>
            <p>
              As a software engineer, I enjoy bridging the gap between
              engineering and design &mdash; combining my technical knowledge
              with my keen eye for design to create a awesome product. My goal
              is to always build applications that are scalable and efficient
              under the hood while providing engaging, pixel-perfect user
              experiences.
            </p>
            <p>
              If you are expecting such things like "
              <strong> High Quality</strong> " , "<strong> Clean Code</strong> "
              , "<strong> In-Time Delivery</strong> " I would be the right
              person . I never compromise with quality of delivery & "{" "}
              <strong>Client Satisfaction</strong>" is my main motto .
            </p>
          </div>
        </section>
      </>
    );
  }
}
