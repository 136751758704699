import React, { Component } from "react";
import ScrollReveal from "scrollreveal";
import Background from "../components/Background";
import Experience from "../components/Experience";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import Skills from "../components/Skills";
import TopButton from "../components/TopButton";
import Projects from "../components/Projects";
export default class Home extends Component {
  componentDidMount() {
    const sr = ScrollReveal({
      reset: false,
      duration: 600,
      easing: "cubic-bezier(.694,0,.335,1)",
      scale: 1,
      viewFactor: 0.3,
    });
    sr.reveal(".background");
    sr.reveal(".skills");
    sr.reveal(".experience", { viewFactor: 0.2 });
    // sr.reveal(".featured-projects", { viewFactor: 0.1 });
    // sr.reveal(".other-projects", { viewFactor: 0.05 });
  }
  render() {
    return (
      <>
        <Intro />
        <Background />
        <Skills />
        <Experience />
        <Projects />
        <Footer />
        <TopButton />
      </>
    );
  }
}
