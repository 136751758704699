import React, { Component } from "react";

export default class Skills extends Component {
  constructor() {
    super();
    this.state = {
      skills: {
        languages: ["JavaScript (ES6)", "TypeScript", "HTML", "CSS", "Java"],
        frameworks: ["Angular", "React", "Vue", "Bootstrap", "Material-UI"],
        databases: ["MySQL", "PostgreSQL", "MongoDB", "SQLite"],
        tools: [
          "Bash",
          "Git & Github",
          "Chrome DevTools",
          "Postman",
          "Vs Code",
        ],
      },
    };
  }

  render() {
    return (
      <>
        <section className="section skills">
          <div className="section__title">Skills</div>
          <div className="section__content">
            {/* <!-- <p>I’ve picked up quite a few skills during the course of my three co-ops, and aim to improve these skills everyday. Here are a few of the technologies that I have experience with:</p> --> */}
            <div className="skillz">
              <div className="skillz__category">
                <div className="skillz__category__label">Languages</div>
                <ul>
                  {this.state.skills.languages.map((item, index) => (
                    <li className="skillz__category__item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="skillz__category">
                <div className="skillz__category__label">
                  Frameworks & Libraries
                </div>
                <ul>
                  {this.state.skills.frameworks.map((item, index) => (
                    <li className="skillz__category__item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="skillz__category">
                <div className="skillz__category__label">Databases</div>
                <ul>
                  {this.state.skills.databases.map((item, index) => (
                    <li className="skillz__category__item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="skillz__category">
                <div className="skillz__category__label">Tools</div>
                <ul>
                  {this.state.skills.tools.map((item, index) => (
                    <li className="skillz__category__item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
